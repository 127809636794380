"use client";
import { FeedFilter } from "@/components/FeedFilter";
import { Box, Row } from "@/components/styled";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FeedFilterData } from "@/utils/constants";
import { SearchBox } from "../search/Components/SearchBox";
import Image from "next/image";
import { AddPostType } from "@/components/AddPostType";
import styled from "styled-components";
import Onboarding from "@/components/Onboarding/Onboarding";
import { OnboardingModal } from "@/components/OnboardingModal";
import { useModal } from "@/hooks/useModal";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useRouter } from "next/navigation";
import { useCreateStripeCustomer } from "@/hooks/useCreateStripeCustomer";
import { useUser } from "@/hooks/useUser";
import { Text } from "@/components/styled";

export const Home = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isPostOpen, setIsPostOpen] = useState(false);
  const { toggleModal, isOpen } = useModal();
  const { isLoggedin } = useIsLoggedIn();
  const router = useRouter();
  const { createStripeCustomer, data } = useCreateStripeCustomer();
  const { data: UserData } = useUser();

  useEffect(() => {
    if (!UserData || UserData.me.stripeCustomerId === null) {
      createStripe();
    }
  }, []);

  const createStripe = async () => {
    try {
      const response = await createStripeCustomer();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isLoggedin) {
      // setIsPostOpen((prev) => !prev);
      router.push("/plus");
      return;
    } else {
      toggleModal();
    }
  };

  return (
    <Box>
      <Row
        gap={25}
        justifyContent={"center"}
        py={["md", "2xl"]}
        alignItems="center"
      ></Row>

      <FeedFilter feedFilter={FeedFilterData.homepage} mobileAlign="c" />
      <OnboardingModal onClose={toggleModal} isVisible={isOpen} />
    </Box>
  );
};

const StyledPostHoverBox = styled(Box)`
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const PostWrapper = styled.div<{ isPostOpen: boolean }>`
  max-height: ${({ isPostOpen }) => (isPostOpen ? "100vw" : "0")};
  opacity: ${({ isPostOpen }) => (isPostOpen ? "1" : "0")};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;
